import { func } from 'prop-types';
import React from 'react';


import { FormattedMessage, intlShape } from '../../../util/reactIntl';

import css from './TermsAndConditions.module.css';

const KEY_CODE_ENTER = 13;

const TermsAndConditions = props => {
  const { onOpenTermsOfService, onOpenPrivacyPolicy, onOpenCookiePolicy, intl } = props;

  const handleClick = callback => e => {
    e.preventDefault();
    callback(e);
  };
  const handleKeyUp = callback => e => {
    // Allow click action with keyboard like with normal links
    if (e.keyCode === KEY_CODE_ENTER) {
      callback();
    }
  };

  const termsLink = (
    <span
      className={css.termsLink}
      onClick={handleClick(onOpenTermsOfService)}
      role="button"
      tabIndex="0"
      onKeyUp={handleKeyUp(onOpenTermsOfService)}
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsLinkText" />
    </span>
  );

  const privacyLink = (
    <span
      className={css.privacyLink}
      onClick={handleClick(onOpenPrivacyPolicy)}
      role="button"
      tabIndex="0"
      onKeyUp={handleKeyUp(onOpenPrivacyPolicy)}
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsPrivacyLinkText" />
    </span>
  );

  // const cookieLink = (
  //   <span
  //     className={css.privacyLink}
  //     onClick={handleClick(onOpenCookiePolicy)}
  //     role="button"
  //     tabIndex="0"
  //     onKeyUp={handleKeyUp(onOpenCookiePolicy)}
  //   >
  //     <FormattedMessage id="AuthenticationPage.termsAndConditionsCookiePolicyText" />
  //   </span>
  // );

  return (
    <div className={css.root}>
      <span className={css.finePrint}>
        {intl.formatMessage(
          { id: 'AuthenticationPage.termsAndConditionsAcceptText2' },
          { termsLink, privacyLink }
          // { termsLink, privacyLink, cookieLink }
        )}
      </span>
      {/* <FieldCheckboxGroup
        name="terms"
        id="terms-accepted"
        optionLabelClassName={css.finePrint}
        options={[
          {
            key: 'tos-and-privacy',
            label: intl.formatMessage(
              { id: 'AuthenticationPage.termsAndConditionsAcceptText2' },
              { termsLink, privacyLink }
            ),
          },
        ]}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({ id: 'AuthenticationPage.termsAndConditionsAcceptRequired' })
        )} */}
      {/* /> */}
    </div>
  );
};

// TermsAndConditions.defaultProps = {};

TermsAndConditions.propTypes = {
  onOpenTermsOfService: func.isRequired,
  onOpenPrivacyPolicy: func.isRequired,
  onOpenCookiePolicy: func.isRequired,
  intl: intlShape.isRequired,
};

export default TermsAndConditions;
