import React, { memo } from 'react';
import css from './InternationalPhoneNumberInput.module.css';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input';
import { useEffect } from 'react';
import ValidationError from '../ValidationError/ValidationError';

const PhoneField = memo(({ input: { onChange, onFocus, onBlur, value }, label, customErrorText, meta, ...rest }) => {

    const { valid, invalid, touched, error } = meta;
    const errorText = customErrorText || error;
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    return (
        <div>
            <p className={css.label}>{label}</p>
            <div tabIndex="0" className={css.inputContainer} onFocus={(event) => onFocus(event)} onBlur={(event) => onBlur(event)} >
                <PhoneInput value={value} onChange={p => onChange(p)} {...rest} />
            </div>
            <ValidationError fieldMeta={fieldMeta} />
        </div>
    );
});

const InternationalPhoneNumberInput = props => {
    const { name, id, label } = props;



    useEffect(() => {
        if (typeof window !== 'undefined') {
            require('react-phone-number-input/style.css');
        }
    }, []);

    return <Field name={name} label={label} component={PhoneField} {...props} />;
};

export default InternationalPhoneNumberInput;