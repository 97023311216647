import React from 'react';

const EyeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{
        fill: 'none',
        cursor: 'pointer',
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Vector">
          <path
            d="M 13.53,8.58516 C 17.311659,14.321592 7.5557489,16.603116 7.42004,11.1151 7.3710936,9.1357251 9.02,7.53516 11,7.53516 c 0.99,0 1.88,0.4 2.53,1.05 z"
            stroke="#013957"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path1"
          />
          <path
            d="m 13.53,8.58516 c 2.370666,4.235993 -0.79684,6.851713 -5.05996,5.05994 -0.65,-0.65 -1.05,-1.54 -1.05,-2.53 C 7.42004,9.13512 9.02,7.53516 11,7.53516 c 0.99,0 1.88,0.4 2.53,1.05 z"
            stroke="#000000"
            strokeOpacity="0.2"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path2"
          />
        </g>
        <g id="Vector_2">
          <path
            d="M16.82 4.8857C15.07 3.5657 13.07 2.8457 11 2.8457C7.46997 2.8457 4.17997 4.9257 1.88997 8.5257C0.989966 9.93572 0.989966 12.3057 1.88997 13.7157C2.67997 14.9557 3.59997 16.0257 4.59997 16.8857"
            stroke="#013957"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path3"
          />
          <path
            d="M16.82 4.8857C15.07 3.5657 13.07 2.8457 11 2.8457C7.46997 2.8457 4.17997 4.9257 1.88997 8.5257C0.989966 9.93572 0.989966 12.3057 1.88997 13.7157C2.67997 14.9557 3.59997 16.0257 4.59997 16.8857"
            stroke="black"
            strokeOpacity="0.2"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path4"
          />
        </g>
        <g id="Vector_3">
          <path
            d="m 4.6768979,16.944252 c 0.5673303,0.476976 1.906519,1.333975 3.1742726,1.825041 1.0265888,0.397651 1.9684945,0.484119 2.7144985,0.496838 1.74539,0.02976 3.060688,-0.296048 4.379031,-0.898739 1.993109,-0.911165 3.792132,-2.453834 5.1653,-4.662392 0.9,-1.41 0.9,-3.78003 0,-5.19008 C 19.613491,7.4599758 17.310568,5.2706793 16.681754,4.7909715"
            stroke="#013957"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path5"
          />
          <path
            d="m 4.6494664,16.944252 c 0.7069957,0.48 2.2341731,1.551466 3.6713981,2.001845 C 12.556719,20.273474 17.99789,17.786349 20.11,13.705 21.129479,12.700486 21.173141,10.388887 20.212621,8.7019535 19.121246,6.7852052 16.975237,5.0179081 16.6765,4.8025342"
            stroke="#000000"
            strokeOpacity="0.2"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path6"
          />
        </g>
      </g>
    </svg>
  );
};

export default EyeIcon;
