import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, NamedLink } from '../../../components';

import css from './SignupForm.module.css';
import { countries } from '../../../util/countries';
import { accountTypes } from '../../../util/accountTypes';
import InternationalPhoneNumberInput from '../../../components/InternationalPhoneNumberInput/InternationalPhoneNumberInput';
import PasswordStrengthBar from '../../../components/PasswordStrengthBar/PasswordStrengthBar';
import FieldPasswordWithBarInput from '../../../components/FieldPasswordWithBarInput/FieldPasswordWithBarInput';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        signupAccountType,
        values,
      } = fieldRenderProps;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.firstNameRoot}
            type="text"
            id={formId ? `${formId}.fname` : 'fname'}
            name="fname"
            autoComplete="given-name"
            label={intl.formatMessage({
              id: 'SignupForm.firstNameLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.firstNamePlaceholder',
            })}
            validate={validators.required(
              intl.formatMessage({
                id: 'SignupForm.firstNameRequired',
              })
            )}
          />
          <FieldTextInput
            className={css.lastNameRoot}
            type="text"
            id={formId ? `${formId}.lname` : 'lname'}
            name="lname"
            autoComplete="family-name"
            label={intl.formatMessage({
              id: 'SignupForm.lastNameLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.lastNamePlaceholder',
            })}
            validate={validators.required(
              intl.formatMessage({
                id: 'SignupForm.lastNameRequired',
              })
            )}
          />

          <FieldTextInput
            type="email"
            id={formId ? `${formId}.email` : 'email'}
            name="email"
            autoComplete="email"
            label={intl.formatMessage({
              id: 'SignupForm.emailLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.emailPlaceholder',
            })}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />

          <InternationalPhoneNumberInput
            type="text"
            id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
            name="phoneNumber"
            defaultCountry="US"
            withCountryCallingCode
            countryCallingCodeEditable={false}
            international
            label={intl.formatMessage({ id: 'SignupForm.phoneNumberLabel' })}
            validate={validators.required(
              intl.formatMessage({ id: 'SignupForm.phoneNumberRequired' })
            )}
          />

          <FieldPasswordWithBarInput
            className={css.password}
            // type="password"
            id={formId ? `${formId}.password` : 'password'}
            name="password"
            autoComplete="new-password"
            label={intl.formatMessage({
              id: 'SignupForm.passwordLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.passwordPlaceholder',
            })}
            validate={passwordValidators}
            validPasswordText={intl.formatMessage(
              {
                id: 'SignupForm.passwordTooShort',
              },
              {
                minLength: validators.PASSWORD_MIN_LENGTH,
              }
            )}
          />

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton
              type="submit"
              // inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>

            <div className={css.line} />

            <div className={css.row}>
              <label className={css.info}>
                <FormattedMessage id="SignupForm.alreadyHaveAccount" />
              </label>
              <NamedLink name="LoginPage" className={css.link}>
                <span>
                  <FormattedMessage id="SignupForm.login" />
                </span>
              </NamedLink>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
