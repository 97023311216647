import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  NamedLink,
  FieldCheckbox,
  FieldCheckboxGroup,
} from '../../../components';

import PasswordFieldTextInput from '../../../components/PasswordFieldTextInput/PasswordFieldTextInput';

import css from './LoginForm.module.css';

const LoginFormComponent = props => {
  useEffect(() => {
    window.sessionStorage.setItem('isRememberMe', false);
  }, []);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          invalid,
        } = fieldRenderProps;

        const rememberedEmail = Cookies.get('email');
        const initialValues = {
          email: rememberedEmail,
          rememberMe: rememberedEmail != undefined ? true : false,
        };

        // email
        const emailLabel = intl.formatMessage({
          id: 'LoginForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'LoginForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'LoginForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'LoginForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'LoginForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'LoginForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'LoginForm.passwordRequired',
        });
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const passwordRecoveryLink = (
          <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
            <FormattedMessage id="LoginForm.forgotPassword2" />
          </NamedLink>
        );

        const rememberMeLabel = intl.formatMessage({
          id: 'LoginForm.rememberMe',
        });

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                defaultValue={initialValues.email}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              {/* <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="current-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordRequired}
              /> */}
              <PasswordFieldTextInput
                className={css.password}
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="current-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordRequired}
              />

              <p className={css.forgotPasswordLink}>{passwordRecoveryLink}</p>

              <FieldCheckbox
                id="rememberMe"
                name="rememberMe"
                label={rememberMeLabel}
                defaultValue={initialValues.rememberMe}
              />
            </div>
            <div className={css.bottomWrapper}>
              {/* <p className={css.bottomWrapperText}>
              <span className={css.recoveryLinkInfo}>
                <FormattedMessage
                  id="LoginForm.forgotPassword2Info"
                  values={{ passwordRecoveryLink }}
                />
              </span>
            </p> */}
              <PrimaryButton
                type="submit"
                // inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="LoginForm.logIn2" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
