import React from 'react';
import PropTypes from 'prop-types';

import EyeSlashIcon from './icons/EyeSlashIcon';
import EyeIcon from './icons/EyeIcon';

import css from './ShowPasswordButton.module.css';

const ShowPasswordButton = ({ showPassword, toggleShowPassword }) => (
  <button
    type="button"
    className={css.showPasswordButton}
    onClick={() => toggleShowPassword(!showPassword)}
  >
    {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
  </button>
);

ShowPasswordButton.propTypes = {
  showPassword: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
};

export default ShowPasswordButton;
