import React from 'react';

const EyeSlashIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      // viewBox="0 0 24 24"
      viewBox="1 1 24 24"
      style={{
        fill: 'none',
        cursor: 'pointer',
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5301 9.46992L9.47017 14.5299C8.82017 13.8799 8.42017 12.9899 8.42017 11.9999C8.42017 10.0199 10.0201 8.41992 12.0001 8.41992C12.9901 8.41992 13.8801 8.81992 14.5301 9.46992Z"
        stroke="#013957"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5301 9.46992L9.47017 14.5299C8.82017 13.8799 8.42017 12.9899 8.42017 11.9999C8.42017 10.0199 10.0201 8.41992 12.0001 8.41992C12.9901 8.41992 13.8801 8.81992 14.5301 9.46992Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8201 5.77047C16.0701 4.45047 14.0701 3.73047 12.0001 3.73047C8.47009 3.73047 5.18009 5.81047 2.89009 9.41047C1.99009 10.8205 1.99009 13.1905 2.89009 14.6005C3.68009 15.8405 4.60009 16.9105 5.60009 17.7705"
        stroke="#013957"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8201 5.77047C16.0701 4.45047 14.0701 3.73047 12.0001 3.73047C8.47009 3.73047 5.18009 5.81047 2.89009 9.41047C1.99009 10.8205 1.99009 13.1905 2.89009 14.6005C3.68009 15.8405 4.60009 16.9105 5.60009 17.7705"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.42017 19.5297C9.56017 20.0097 10.7701 20.2697 12.0001 20.2697C15.5301 20.2697 18.8201 18.1897 21.1101 14.5897C22.0101 13.1797 22.0101 10.8097 21.1101 9.39969C20.7801 8.87969 20.4201 8.38969 20.0501 7.92969"
        stroke="#013957"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.42017 19.5297C9.56017 20.0097 10.7701 20.2697 12.0001 20.2697C15.5301 20.2697 18.8201 18.1897 21.1101 14.5897C22.0101 13.1797 22.0101 10.8097 21.1101 9.39969C20.7801 8.87969 20.4201 8.38969 20.0501 7.92969"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5099 12.7002C15.2499 14.1102 14.0999 15.2602 12.6899 15.5202"
        stroke="#013957"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5099 12.7002C15.2499 14.1102 14.0999 15.2602 12.6899 15.5202"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.47 14.5303L2 22.0003"
        stroke="#013957"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.47 14.5303L2 22.0003"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 2L14.53 9.47"
        stroke="#013957"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 2L14.53 9.47"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeSlashIcon;
