import React from 'react';
import InfoIcon from './images/info.svg';
import SuccessIcon from './images/success.svg';
import css from './PasswordStrengthBar.module.css';
import { FormattedMessage } from 'react-intl';

const PasswordStrengthBar = props => {
    const { value, fieldMeta, validPasswordText } = props;
    const strengths = [
        { key: 1, value: 'veryWeak', barCss: 'veryWeak', textCss: 'veryWeakText' },
        { key: 2, value: 'weak', barCss: 'weak', textCss: 'weakText' },
        { key: 3, value: 'good', barCss: 'good', textCss: 'goodText' },
        { key: 4, value: 'veryGood', barCss: 'veryGood', textCss: 'veryGoodText' }
    ]

    const calculatePasswordStrength = (password) => {
        let strength = 1;

        const regExps = [
            /[A-Z]/,
            /\d/,
            /\W/,
        ]

        regExps.forEach(exp => {
            if (exp.test(password)) {
                strength += 1
            }
        })

        return strengths.find(s => s.key === strength);
    }

    const getBar = (position, strength) => {
        if (position <= strength.key) {
            return <div className={css[strength.barCss]} />
        } else {
            return <div className={css.none} />
        }
    }

    const strength = calculatePasswordStrength(value);

    return (
        <div className={css.root}>
            {(fieldMeta.touched && fieldMeta.error) || (fieldMeta.error && value) ?
                <div className={css.infoContainer}>
                    <img className={css.icon} src={InfoIcon} />
                    <span className={css.info}>
                        {fieldMeta.error}
                    </span>
                </div> :

                value && (
                    <div className={css.infoContainer}>
                        <img className={css.icon} src={SuccessIcon} />
                        <span className={css.infoSuccess}>
                            {validPasswordText}
                        </span>
                    </div>)

            }

            {value &&
                <>
                    <div className={css.barsContainer}>
                        {getBar(1, strength)}
                        {getBar(2, strength)}
                        {getBar(3, strength)}
                        {getBar(4, strength)}
                    </div>

                    <span className={css[strength.textCss]} >
                        <FormattedMessage id={"PasswordStrengthBar." + strength.value} />
                    </span>
                </>
            }

        </div>
    );
};

export default PasswordStrengthBar;
